<template>
  <div class="titleBarDiv">
    <div class="commonLineDiv"></div>
    <div class="commonTitleDiv ws_oneLine" :style="{color:isWarn?'red':'#3796F3'}">
      {{ title || "" }}
    </div>
  </div>
</template>
<script>
export default {
  props: ["title","isWarn"],
};
</script>
<style lang="scss" scoped>
@import "~styles/variables.scss";
.titleBarDiv {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0.4rem 0 0.2rem;
  .commonLineDiv {
    width: 0.1rem;
    height: 0.4rem;
    background-color: $ws-mainColor;
    border-radius: 0.05rem;
    margin-right: 0.2rem;
  }
  .commonTitleDiv {
    font-size: 14px;
    color: $ws-mainColor;
    font-weight: bold;
  }
}
</style>
