import Html2Canvas from 'html2canvas'
import jsPDF from 'jspdf'
const getReportPdf = function(elName, fileName, wsScale = 2) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            try {
                // var html = document.querySelector(elName)
                // let contentWidth = html.clientWidth // 获得该容器的宽
                // let contentHeight = html.clientHeight // 获得该容器的高
                // let canvas = document.createElement('canvas')
                // let scale = 1 // 解决清晰度问题，先放大 2倍

                // canvas.width = contentWidth * scale // 将画布宽&&高放大两倍
                // canvas.height = contentHeight * scale
                // canvas.getContext('2d').scale(scale, scale)

                // let opts = {
                //     scale: scale,
                //     canvas: canvas,
                //     // width: contentWidth,
                //     // height: contentHeight,
                //     useCORS: true
                // }
                // window.Html2canvas ||
                //     Html2Canvas(html, opts)
                //     .then(canvas => {
                //         var contentWidth = canvas.width
                //         var contentHeight = canvas.height

                //         //一页pdf显示html页面生成的canvas高度;
                //         var pageHeight = (contentWidth / 592.28) * 841.89
                //             //未生成pdf的html页面高度
                //         var leftHeight = contentHeight
                //             //页面偏移
                //         var position = 0
                //             //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
                //         var imgWidth = 595.28
                //         var imgHeight = (592.28 / contentWidth) * contentHeight

                //         var pageData = canvas.toDataURL('image/jpeg', 1.0)

                //         var pdf = new jsPDF('', 'pt', 'a4')

                //         //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
                //         //当内容未超过pdf一页显示的范围，无需分页
                //         if (leftHeight < pageHeight) {
                //             pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
                //         } else {
                //             while (leftHeight > 0) {
                //                 pdf.addImage(
                //                     pageData,
                //                     'JPEG',
                //                     0,
                //                     position,
                //                     imgWidth,
                //                     imgHeight
                //                 )
                //                 leftHeight -= pageHeight
                //                 position -= 841.89
                //                     //避免添加空白页
                //                 if (leftHeight > 0) {
                //                     pdf.addPage()
                //                 }
                //             }
                //         }
                //         pdf.save(fileName)
                //         resolve(fileName)
                //             //
                //     })
                //     .catch(err => {
                //         reject(err)
                //     })
                const scale = wsScale //2
                    // 滚动到顶部，避免打印不全
                document.documentElement.scrollTop = 0

                Html2Canvas(document.querySelector(elName), {
                    // allowTaint: true, // Whether to allow cross-origin images to taint the canvas
                    scale, // The scale to use for rendering. Defaults to the browsers device pixel ratio.
                    useCORS: true //允许canvas画布内 可以跨域请求外部链接图片, 允许跨域请求。
                }).then(canvas => {
                    // const contentWidth = canvas.width / wsScale
                    // const contentHeight = canvas.height / wsScale

                    const contentWidth = wsScale == 2 ? canvas.width / 2 : canvas.width
                    const contentHeight = wsScale == 2 ? canvas.height / 2 : canvas.height
                    const pdf = new jsPDF('', 'pt', [contentWidth, contentHeight])
                    const pageData = canvas.toDataURL('image/jpeg', 1.0)

                    pdf.addImage(pageData, 'JPEG', 0, 0, contentWidth, contentHeight)
                    pdf.save(`${fileName}.pdf`)
                    resolve(fileName)
                })
            } catch (err) {
                reject(err)
            }
        }, 1000)
    })
}

export default getReportPdf