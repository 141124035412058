// mixins全局混入 相当混入的每一个vue文件都会有如下变量组件方法
import BaseInfoContainer from '../components/baseInfoContainer'
import TitleBar from '../components/titleBar.vue'
import ContentBox from '../components/contentBox.vue'
import Charts from '../components/charts.vue'
export const mixins = {
    components: {
        // 基本信息组件
        BaseInfoContainer,
        // 标题组件
        TitleBar,
        // 内容组件
        ContentBox,
        // 图标
        Charts
    },
    filters: {
        filterColorText(val) {
            var str = val.replaceAll('#', '$')
            if (!str.includes('$')) {
                return val
            }
            while (str.includes('$')) {
                if (!str.includes('$')) break
                for (var i = 0; i < 2; i++) {
                    if (i == 0) {
                        str = str.replace('$', '<span style="color:#000;font-weight:700;">')
                    } else {
                        str = str.replace('$', '</span>')
                    }
                }
            }
            return str
        }
    },
    data() {
        return {
            //内容box左上角标签 结果
            wsListResultImg: require('../assets/images/caseReportNew/list_result.png'), //列表结果图片
            //内容box左上角标签 建议
            wsListSuggestImg: require('../assets/images/caseReportNew/list_suggest.png'), //列表建议图片
            isHiddenBtn: false,
            queryParams: undefined,
            isExport: false
        }
    },
    created() {
        this.isHiddenBtn = this.$route.query.isHiddenBtn == 'true' ? true : false
        this.isExport = this.$route.query.isExport == 'true' ? true : false
        this.queryParams = this.$route.query
    }
}
