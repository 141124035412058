import Axios from 'axios'
import { Loading } from 'element-ui'
Axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
var loadingInstance
const service = Axios.create({
        // axios中请求配置有baseURL选项，表示请求URL公共部分
        baseURL: process.env.VUE_APP_BASE_API,
        // 超时
        timeout: 50000
    })
    // request拦截器
service.interceptors.request.use(config => {
    var loadingText = '报告加载中...';
    if (config.url.includes('paper/report/export') || config.url.includes('paper/teamReport/export')) {
        loadingText = '导出答卷中...'
    }

    loadingInstance = Loading.service({
        lock: true,
        text: loadingText,
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.5)'
    })
    return config
})

// 响应拦截器
service.interceptors.response.use(
    res => {
        loadingInstance.close()
        return res.data
    },
    error => {
        return Promise.reject(error)
    }
)

export default service
