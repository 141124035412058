<template>
  <div
    class="commonContentDiv"
    :style="{ backgroundColor: isBackGroundColor ? '#fff' : '' }"
  >
   <p v-html="content"></p>
    <img :src="imgSrc" v-if="imgSrc" class="commonImg" />
  </div>
</template>
<script>
export default {
  props: ["imgSrc", "content", "isBackGroundColor"],
};
</script>
<style lang="scss" scoped>
@import "~styles/variables.scss";
.commonContentDiv {
  font-size: 12px;
  line-height: 30px;
  color: $ws-text-color-black;
  padding: 0.2rem;
  border-radius: 0.12rem;
  text-indent: 0.56rem;
  margin-bottom: 0.2rem;
  position: relative;
  .commonImg {
    position: absolute;
    left: 0;
    top: 0;
    width: 0.7rem;
    height: 0.7rem;
    // background-color: pink;
    z-index: 1;
  }
}
</style>
