<template>
  <!-- 基本信息容器 -->
  <div class="baseInfoContainer" v-if="myDataObj.baseInfoObj">
    <div class="baseInfoTitle ws_oneLine">
      {{ myDataObj.baseInfoObj.reportName || "" }}
    </div>
    <div class="baseInfoList">
      <div
        class="baseInfoItem"
        v-for="(item, index) in myDataObj.baseInfoObj.baseInfoList"
        :key="index"
        v-show="item.title && item.title.length > 0"
      >
        <!-- <img class="baseInfoItemImg" :src="item.imgStr" /> -->
        <img
          class="baseInfoItemImg"
          :src="item.imgStr + '?' + new Date().getTime()"
          crossOrigin="anonymous"
        />
        <div class="baseConetent ws_oneLine">{{ item.title || "" }}</div>
      </div>
    </div>
    <div class="baseInfoBtnList" v-if="!isHiddenBtn">
      <div class="exportReportBtn" @click="ws_exportReportAction">导出报告</div>
      <div class="exportPaperBtn" @click="ws_exportPaperAction">导出问卷</div>
    </div>
  </div>
</template>
<script>
import getReportPdf from "../utils/getReportPdf";
// import htmlToPdf from "../utils/htmlToPdf";
import { ws_exportCasePaper, ws_exportTeamPaper } from "../api/report";
//reportType 1:个体  2:团体
export default {
  props: ["myDataObj", "isHiddenBtn", "isExport", "reportType"],
  data() {
    return {};
  },
  mounted() {
    if (this.isExport) {
      this.ws_exportReportAction();
    }
  },
  methods: {
    //导出报告事件
    ws_exportReportAction() {
      //导出PDF
      // htmlToPdf.downloadPDF(document.querySelector("#containerId"), "我的PDF");
      // htmlToPdf.printOut(document.querySelector("#containerId"));
      // return;

      // document.documentElement.style.width = "794px";
      // const htmlHeight = document.getElementById("containerId").offsetHeight;
      // const printHeight = 1090;
      // if (htmlHeight > printHeight) {
      //   // 子元素总数
      //   const countElement =
      //     document.getElementById("containerId").childElementCount;
      //   for (var i = 0; i < countElement; i++) {
      //     let chilOffsetTop =
      //       document.getElementById("containerId").children[i].offsetTop;
      //     let chilOffsetHeight =
      //       document.getElementById("containerId").children[i].offsetHeight;
      //       document.getElementById("containerId").children[i],
      //       chilOffsetTop
      //     );
      //     if (
      //       chilOffsetTop < printHeight &&
      //       printHeight - chilOffsetTop < chilOffsetHeight
      //     ) {
      //       document.getElementById("containerId").children[
      //         i
      //       ].style.paddingTop = printHeight - chilOffsetTop + 50 + "px";
      //     } else if (
      //       chilOffsetTop > printHeight &&
      //       printHeight - (chilOffsetTop % printHeight) < chilOffsetHeight
      //     ) {
      //       document.getElementById("containerId").children[
      //         i
      //       ].style.paddingTop =
      //         printHeight - (chilOffsetTop % printHeight) + 100 + "px";
      //     }
      //   }
      // }

      document.getElementById("containerId").style.width = "595.28px";
      this.$message({
        message: "正在导出报告中,请稍等...",
        type: "success",
      });
      setTimeout(() => {
        // getReportPdf("#containerId", `${this.myDataObj.baseInfoObj.reportName + this.myDataObj.baseInfoObj.baseInfoList[0].title + this.myDataObj.baseInfoObj.baseInfoList[1].title  + 'pdf' }`);
        getReportPdf(
          "#containerId",
          `${
            this.myDataObj.baseInfoObj.reportName +
            this.myDataObj.baseInfoObj.baseInfoList[0].title +
            this.myDataObj.baseInfoObj.baseInfoList[1].title
          }`
        );
      }, 1000);
    },

    //导出答卷事件
    ws_exportPaperAction() {
      //reportType 1:个体报告  2:团体报告
      var that = this;
      if (this.reportType == 1) {
        ws_exportCasePaper({
          token: that.queryParams.token,
          prams: {
            reportId: that.queryParams.reportId,
          },
        }).then((res) => {
          //成功
          if (res.code == 200) {
            this.download(res.msg);
          } else {
            this.msgError(res.msg);
          }
        });
      } else if (this.reportType == 2) {
        ws_exportTeamPaper({
          token: that.queryParams.token,
          prams: {
            activityId: that.queryParams.activityId,
            reportTpye: that.queryParams.reportTpye,
            paperId: that.queryParams.paperId || "",
          },
        }).then((res) => {
          //成功
          if (res.code == 200) {
            this.download(res.msg);
          } else {
            this.msgError(res.msg);
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~styles/variables.scss";
/* 语法  @media screen and (max-width:768px) and (min-width:320px){}*/
@media screen and (max-width: 768px) {
  /* 1.在超小屏设备的时候 768px以下   当前容器的宽度100%   并且背景设置为蓝色 */
  .baseInfoContainer {
    .baseInfoTitle {
      text-align: left;
    }
    .baseInfoList {
      display: flex;
      justify-content: left !important;
      .baseInfoItem {
        width: 50%;
      }
    }
    .baseInfoBtnList {
      display: none !important;
    }
  }
}
// 基本信息容器
.baseInfoContainer {
  position: relative;
  margin-bottom: 0.3rem;
  .baseInfoTitle {
    font-size: 0.3rem;
    padding: 0.28rem 0.2rem 0.28rem;
    color: $ws-text-color-black;
    text-align: center;
    font-weight: bold;
  }
  .baseInfoList {
    padding: 0rem 0.2rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .baseInfoItem {
      height: 0.6rem;
      display: flex;
      align-items: center;
      // padding-right:0.4rem;
      // border: 1px solid pink;
      .baseInfoItemImg {
        width: 0.4rem;
        height: 0.4rem;
        max-width: 0.4rem;
        // vertical-align: middle;
      }
      .baseConetent {
        font-size: 0.28rem;
        // padding: 0.28rem 0.2rem 0.2rem;
        padding: 0.28rem 0.2rem 0.2rem 0.05rem;
        color: $ws-text-color-black;
      }
      &:last-child {
        padding-right: 0rem;
      }
    }
  }
  .baseInfoBtnList {
    position: absolute;
    display: flex;
    top: 0.2rem;
    right: 0.2rem;
    .exportReportBtn {
      cursor: pointer;
      height: 0.4rem;
      line-height: 0.4rem;
      width: 1.2rem;
      font-size: 0.24rem;
      color: #fff;
      margin-right: 0.2rem;
      border-radius: 0.12rem;
      text-align: center;
      background-color: $ws-mainColor;
    }
    .exportPaperBtn {
      cursor: pointer;
      height: 0.4rem;
      line-height: 0.4rem;
      width: 1.2rem;
      font-size: 0.24rem;
      color: #fff;
      border-radius: 0.12rem;
      text-align: center;
      background-color: #02c0fe;
    }
  }
}
</style>
