import request from '../utils/requst'

// 新版个体报告
export function getPersonalReport(data) {
    return request({
        headers: {
            Authorization: 'Bearer ' + data.token
        },
        url: '/measure/activity/personalReports',
        method: 'get',
        params: data
    })
}

// 新版团体报告
export function getGroupReport(data) {
    return request({
        headers: {
            Authorization: 'Bearer ' + data.token
        },
        url: '/measure/activity/groupReports/' + data.reportId,
        method: 'get'
    })
}

// 个体报告
export function getPersonReportData(data) {
    return request({
        headers: {
            token: data.token
        },
        url: '/paper/report/info',
        method: 'post',
        data: data
    })
}

// 儿少个体报告
export function getChildrenPersonReportData(data) {
    return request({
        headers: {
            token: data.token
        },
        url: '/paper/report/childrenInfo',
        method: 'post',
        data: data
    })
}

// 团体报告
export function getTeamReportData(data) {
    return request({
        headers: {
            Authorization: data.token
        },
        url: '/paper/teamReport/info',
        method: 'post',
        data: data
    })
}

// 儿少团体报告-detail
export function getChildrenTeamReportDetailData(data) {
    return request({
        headers: {
            Authorization: data.token
        },
        url: 'mirrorego/stuStatistic/detail',
        method: 'post',
        data: data
    })
}

// 儿少团体报告-itemdetail2
export function getChildrenTeamReportItemdetail2Data(data) {
    return request({
        headers: {
            Authorization: data.token
        },
        url: 'mirrorego/stuStatistic/itemdetail2',
        method: 'post',
        data: data
    })
}

// 团体报告
export function getWarnReportData(data) {
    return request({
        headers: {
            Authorization: 'Bearer ' + data.token
        },
        url: '/paper/report/reportData',
        method: 'get',
        params: data
    })
}


// 导出答卷--个体
export function ws_exportCasePaper(data) {
    return request({
        headers: {
            Authorization: data.token
        },
        url: '/paper/report/export',
        method: 'get',
        params: data.prams
    })
}

// 导出答卷
export function ws_exportTeamPaper(data) {
    return request({
        headers: {
            Authorization: data.token
        },
        url: '/paper/teamReport/export',
        method: 'get',
        params: data.prams
    })
}
