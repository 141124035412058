<template>
  <!-- bar条形图  pie饼状图 line折线图 radar雷达图-->
  <div class="barChart" id="myChart" :style="{ height: height }" />
</template>

<script>
import echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
import resize from "../utils/resize";

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "barChart",
    },
    // width: {
    //   type: String,
    //   default: "300px",
    // },
    height: {
      type: String,
      default: "300px",
    },
    option: {
      type: Object,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
      this.chart.resize();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");

      this.chart.setOption(this.option);
    },
  },
};
</script>
